import useAsyncFetch from "./useAsyncFetch";

const usePrizeVaultData = ({ immediate = false }: { immediate?: boolean } = {}) => {
	const { data: prizeVaultData, refresh: refreshPrizeVaultData } = useAsyncFetch({
		path: "/rest/deposit-streak/info/",
		method: "get",
		options: {
			immediate,
			default: () => ({
				maxNumberOfDeposit: 8,
				currentCountDeposit: 0,
				finishedAt: "0000-00-00 00:00:00"
			})
		}
	});

	const canPlayVault = computed(
		() => prizeVaultData.value?.currentCountDeposit === prizeVaultData.value?.maxNumberOfDeposit
	);
	const depositsRemaining = computed(
		() => (prizeVaultData.value?.maxNumberOfDeposit ?? 8) - (prizeVaultData.value?.currentCountDeposit ?? 0)
	);

	const { data: prizeData, execute: applyPrize } = useAsyncFetch({
		path: "/rest/deposit-streak/apply-prize/",
		method: "get",
		options: {
			immediate: false,
			transform(data) {
				return data.data;
			}
		},
		fetchOptions: () => ({
			onResponse: () => {
				refreshPrizeVaultData();
			}
		})
	});

	return { prizeVaultData, canPlayVault, depositsRemaining, prizeData, refreshPrizeVaultData, applyPrize };
};

export default usePrizeVaultData;
